import "./ModalProcessAlert.scss";
import { useState, useRef, useEffect } from "react";
import MainAppGenericModal from "components/MainApp/atoms/Modals/GenericModal";
import backArrow from "assets/images/back-arrow-icon.svg";
import backArrowLarge from "assets/images/back-arrow.svg";
import heistIcon from "assets/images/heist-icon.svg";
import triedHeistIcon from "assets/images/tried-heist-icon.svg";
import falseAlarmIcon from "assets/images/false-alarm-icon.svg";
import notProcessIcon from "assets/images/not-process-icon.svg";
import Loader from "components/MainApp/atoms/Loader";
import { GuardStatusService } from "services/GuardStatusService";
import { EventRepository } from "helpers/EventRepository";
import useRequest from "hooks/useRequest";
import useWindowSize from "hooks/useWindowSize";
import i18n from "i18n";
import { useStore } from "store/context";
import { getAlarmType } from "shared/components/guard/Parser";

const ModalProcessAlert = ({
  isOpen,
  setIsOpen,
  alert,
  handleBack,
  reloadAlerts
}) => {
  const [{ alertList }, dispatch] = useStore();
  const { isMobile } = useWindowSize();
  const [optionSelected, setOptionSelected] = useState(null);
  const [comment, setComment] = useState("");
  const inputCommentRef = useRef(null);

  const proccessAlarmOptions = [
    { id: "THEFT", icon: heistIcon, text: "Robo" },
    { id: "ATTEMPTED_THEFT", icon: triedHeistIcon, text: "Intento de robo" },
    { id: "FALSE_ALARM", icon: falseAlarmIcon, text: "Falsa alarma" },
    { id: "NOT_PROCESS", icon: notProcessIcon, text: "No procesar ahora" }
  ];

  const handleSelected = option => {
    optionSelected?.id === option.id
      ? setOptionSelected(null)
      : setOptionSelected(option);
  };

  const handleCommentFocus = () => {
    inputCommentRef?.current.classList.add("comment-focused");
  };

  const handleCommentBlur = () => {
    inputCommentRef?.current.classList.remove("comment-focused");
  };

  const handleCommentChange = e => {
    setComment(e.target.value);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setOptionSelected(null);
  };

  const { loading, beforeSubmit, afterSubmit, dealWithError } = useRequest();

  const handleSubmit = () => {
    beforeSubmit();
    const data = {
      comments: comment || "Sin comentario",
      status: optionSelected.id
    };
    GuardStatusService.updateGuardStatus(alert.id, data)
      .then(() => {
        dispatch({
          type: "setAlertList",
          payload: alertList.filter(a => a !== alert.id)
        });

        afterSubmit();
        EventRepository.notificationSend({
          label: "La alerta se procesó con éxito.",
          type: "success"
        });
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      })
      .finally(() => {
        setIsOpen(false);
        handleBack();

        const currentPage = 1;
        reloadAlerts({ page: currentPage });
      });
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error"
      });
    }
  };

  useEffect(() => {
    const guardItemDetailDiv = document.querySelector(".guard-item-detail");

    if (loading && isMobile) {
      guardItemDetailDiv.classList.add("height-max-viewport");
    } else {
      guardItemDetailDiv.classList.remove("height-max-viewport");
    }
  }, [loading]);

  const contentPopUp = (
    <section className="modal-process-alert">
      <header>
        <img
          src={isMobile ? backArrowLarge : backArrow}
          alt="volver"
          onClick={() => setIsOpen(false)}
        />
        <h4>Procesar alerta</h4>
      </header>
      <div className="modal-process-alert__content">
        <p>Elige una opción para procesar esta alerta</p>
        <div className="modal-process-alert__options">
          {proccessAlarmOptions.map((option, idx) => (
            <button
              key={idx}
              className={`${optionSelected?.id === option.id &&
                `option-${option.id}-active`}
                                modal-process-alert__options--button`}
              onClick={() => handleSelected(option)}
            >
              <picture>
                <img src={option.icon} />
              </picture>
              <h3>{option.text}</h3>
            </button>
          ))}
        </div>
        <div className="modal-process-alert__comment" ref={inputCommentRef}>
          <span>Descripción</span>
          <textarea
            placeholder="Ingresa un comentario"
            onFocus={handleCommentFocus}
            onBlur={handleCommentBlur}
            onChange={handleCommentChange}
          />
        </div>
      </div>
      <footer>
        <button onClick={handleCancel}>Cancelar</button>
        <button disabled={!optionSelected} onClick={handleSubmit}>
          Confirmar
        </button>
      </footer>
    </section>
  );

  return (
    <>
      <MainAppGenericModal
        open={isOpen}
        content={contentPopUp}
        handleClose={() => setIsOpen(false)}
        classContainer="modal-process-alert-container"
        classModalContainer="modal-process-alert-modal-container"
      />
      {loading && <Loader />}
    </>
  );
};

export default ModalProcessAlert;
