import React, { useState, useEffect } from "react";
import "./styles.scss";
import Box from '@mui/material/Box';
import { RichTreeView } from "@mui/x-tree-view";

function mapCategoriesToTreeView(categories) {
  return categories.map(category => ({
    id: category.id,
    label: category.name,
    children: [
      ...mapCategoriesToTreeView(category.subcategories),
    ]
  }));
}

function findCategoryOrProductById(categories, id) {
  for (const category of categories) {
    if (category.id === id) {
      return { type: 'category', item: category };
    }
    const foundInSubcategories = findCategoryOrProductById(category.subcategories, id);
    if (foundInSubcategories) {
      return foundInSubcategories;
    }
  }
  return null;
}

function removeParentCategories(selectedCategories) {
  return selectedCategories.filter(category => {
    const hasSelectedSubcategory = category.subcategories.some(subcategory =>
      selectedCategories.some(selectedCategory => selectedCategory.id === subcategory.id)
    );
    return !hasSelectedSubcategory;
  });
}

export default function RecursiveSelect({
  categoriesTree,
  onSelectionChange,
  selectedItems = [],
 }) {
  const treeViewItems = mapCategoriesToTreeView(categoriesTree);

        const handleSelectionChange = (event, newSelectedItems) => {
      const selectedCategories = [];
      const productsInSelectedCategories = [];

      newSelectedItems.forEach(item => {
        const foundItem = findCategoryOrProductById(categoriesTree, item);
        if (foundItem) {
          if (foundItem.type === 'category') {
            selectedCategories.push(foundItem.item);
          }
        }
      });

      const filteredCategories = removeParentCategories(selectedCategories);

      // Función recursiva para obtener todos los productos de una categoría y sus subcategorías
      const getAllProducts = (category) => {
        let products = [...category.products];
        category.subcategories.forEach(subCategory => {
          products = products.concat(getAllProducts(subCategory));
        });
        return products;
      };

      // Guardamos en productsInSelectedCategories los productos que haya en cada categoría seleccionada
      filteredCategories.forEach(category => {
        // Si la categoría tiene subcategorías seleccionadas, usamos los productos de las subcategorías
        const selectedSubCategories = category.subcategories.filter(subCategory =>
          newSelectedItems.includes(subCategory.id)
        );

        if (selectedSubCategories.length > 0) {
          selectedSubCategories.forEach(subCategory => {
            productsInSelectedCategories.push(...subCategory.products);
          });
        } else {
          // Si ninguna subcategoría está seleccionada, tomamos todos los productos de todas las subcategorías
          productsInSelectedCategories.push(...getAllProducts(category));
        }
      });

      onSelectionChange(newSelectedItems, filteredCategories, productsInSelectedCategories);
    };

  return (
    <Box sx={{ minHeight: 352, minWidth: 290 }} className="recursive-select">
      <RichTreeView

        multiSelect
        checkboxSelection
        items={treeViewItems}
        selectedItems={selectedItems}
        onSelectedItemsChange={handleSelectionChange}
      />
    </Box>
  );
}
