
import "./styles.scss"
import * as React from "react";
import { Card, CardContent } from "@material-ui/core";
import { formatDateAndHour } from "helpers/Parsers";
import useWindowSize from "hooks/useWindowSize";
import arrowIconSmall from "assets/images/arrow-icon-small.svg"
import locationIconXs from "assets/images/location-icon-xs.svg"
import Icon from "components/MainApp/atoms/Icon/icon";

const renderStatus = status => {
    switch (status) {
      case "ALARM":
        return <span className="new-alert">Nueva alerta</span>;
      case "FALSE_ALARM":
        return <span className="false-alarm">Falsa alarma</span>;
      case "ATTEMPTED_THEFT":
        return <span className="attempted-theft">Intento de robo</span>;
      case "THEFT":
        return <span className="theft">Robo</span>;
      default:
        return <></>;
    }
  };

const GuardItem = ({ 
    alert,
    setAlertSelected
}) => {
    const { date, hour } = formatDateAndHour(alert.created_at);
    const { isMobile } = useWindowSize();

    return isMobile ? (
      <Card mt={0} ml={0} className="card-content-mobile">
        <CardContent onClick={() => setAlertSelected(alert)}>
          <header>
            <h4>{alert?.device?.name}</h4>
            <p>
              {alert?.tag && <Icon name="tags" fill={"#3C7AF5"} />}
              {renderStatus(alert?.status)}
            </p>
          </header>
          <footer>
            <p>
              <img src={locationIconXs} />
              {alert?.device?.location?.name}
            </p>
            <p>{date} {hour}</p>
          </footer>
        </CardContent>
      </Card>
    ) : (
      <Card mt={0} ml={0} className="card-content-desk">
        <CardContent onClick={() => setAlertSelected(alert)}>
          <p>{date}</p>
          <p>{hour}</p>
          <p>{alert?.device?.name}</p>
          <p>{alert?.device?.location?.name}</p>
          <p>
            {alert?.tag && <Icon name="tags" fill={"#3C7AF5"} />}
            {renderStatus(alert?.status)}
          </p>
          <span>
            <img src={arrowIconSmall} />
          </span>
        </CardContent>
      </Card>
    );
}

export default GuardItem;