import "./ModalAlerts.scss";
import { useEffect, useState } from "react";
import { useStore } from "store/context";
import { GuardStatusService } from "services/GuardStatusService";
import useWindowSize from "hooks/useWindowSize";
import MainAppGenericModal from "components/MainApp/atoms/Modals/GenericModal";
import Loader from "components/MainApp/atoms/Loader";
import closeIcon from "assets/images/close-icon.svg";
import alarmIcon from "assets/images/alarm-icon.svg";
import clockIcon from "assets/images/clock-icon.svg";
import locationIcon from "assets/images/location-icon-small.svg";
import skuIcon from "assets/images/sku-icon-small.svg";
import arrowIcon from "assets/images/arrow-icon-small.svg";
import backArrow from "assets/images/back-arrow.svg";
import { formatDateAndHour } from "helpers/Parsers";

const ModalAlerts = ({ isOpen, setIsOpen, setAlertSelected }) => {
  const { isMobile } = useWindowSize();
  const [{ alertList }] = useStore();
  const [alerts, setAlerts] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const { date, hour } = formatDateAndHour(lastUpdate);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSeeDetail = alert => {
    setIsOpen(false);
    setAlertSelected(alert);
  };

  const contentPopUp = (
    <section className="modal-alerts">
      <img src={isMobile ? backArrow : closeIcon} onClick={handleClose} />
      <header>
        <img src={alarmIcon} />
        <div>
          <h4>Alerta</h4>
          <p>
            <img src={clockIcon} /> {date} {hour}
          </p>
        </div>
      </header>
      <div className="modal-alerts__items">
        {alerts.map(alert => (
          <article onClick={() => handleSeeDetail(alert)}>
            <div>
              <h3>{alert?.device?.name}</h3>
              <h4>
                <img src={locationIcon} />
                {alert?.device?.location?.name}
              </h4>
            </div>
            <div>
              <img src={skuIcon} />
              <p>SKU {alert?.tag?.sku}</p>
              <p>{alert?.tag?.rfid}</p>
            </div>
            <img src={arrowIcon} />
          </article>
        ))}
      </div>
      <footer>
        <button onClick={handleClose}>No procesar</button>
      </footer>
    </section>
  );

  useEffect(() => {
    if (alertList.length > 0) {
      setIsLoading(true);

      const promises = alertList.map(alert =>
        GuardStatusService.getAlarmGuardStatusById(alert)
      );

      Promise.all(promises)
        .then(responses => setAlerts(responses))
        .finally(() => setIsLoading(false));
    }
  }, [alertList]);

  useEffect(() => {
    if (alerts.length > 0) {
      setLastUpdate(new Date(alerts[alerts?.length - 1].created_at));
    }
  }, [alerts]);

  if (alertList.length === 0) return <></>;

  if (isLoading) return <Loader />;

  return (
    <>
      <MainAppGenericModal
        open={isOpen}
        content={contentPopUp}
        handleClose={handleClose}
        classContainer="modal-alerts-container"
        classModalContainer="modal-alerts-modal-container"
      />
    </>
  );
};

export default ModalAlerts;
