import "./ModalFilters.scss";
import { useEffect, useState } from "react";
import { DeviceService } from "services/DeviceService";
import MainAppGenericModal from "components/MainApp/atoms/Modals/GenericModal";
import Icon from "components/MainApp/atoms/Icon/icon";
import MultiSelectInput from "components/MainApp/atoms/Forms/MultiSelectInput";
import closeIcon from "assets/images/close-icon.svg";
import DateRangePicker from "components/MainApp/atoms/Filters/DateRangePicker/DateRangePicker";
import { formatDateToYyyyMmDd } from "helpers/Parsers";

const statusOptions = [
  { id: "ALARM", value: "Nueva alerta" },
  { id: "ATTEMPTED_THEFT", value: "Intento de robo" },
  { id: "THEFT", value: "Robo" },
  { id: "FALSE_ALARM", value: "Falsa alarma" }
];

const DEFAULT_FILTER_STATUS = { id: "ALARM", value: "Nueva alerta" }

const rangeToday =  {
  start: formatDateToYyyyMmDd(new Date()),
  end: formatDateToYyyyMmDd(new Date())
}

const ModalFilters = ({ isOpen, setIsOpen, filters, setFilters }) => {
  const [devicesOptions, setDevicesOptions] = useState([]);
  const [selectDevices, setSelectDevices] = useState([]);
  const [selectStatus, setSelectStatus] = useState([DEFAULT_FILTER_STATUS]);
  const [selectDateRange, setSelectDateRange] = useState(rangeToday);

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleDeleteFilters = () => {
    setFilters({ devices: [], status: [DEFAULT_FILTER_STATUS.id], dateRange: rangeToday});
    setSelectDevices([]);
    setSelectStatus([DEFAULT_FILTER_STATUS]);
    handleClose();
  };

  const handleChangeDevices = deviceSelected => {
    setSelectDevices(deviceSelected);
  };

  const handleChangeStatus = statusSelected => {
    setSelectStatus(statusSelected);
  };

  const handleApply = () => {
    setFilters({
      devices: selectDevices.map(device => device.id),
      status: selectStatus.map(status => status.id),
      dateRange: selectDateRange
    })
    handleClose();
  };

  useEffect(() => {
    DeviceService.readersDevices({
      no_page: 1,
      enabled: true,
      includeType: "LOSS_PREVENTION"
    }).then(devices => {
      setDevicesOptions(
        devices.map(d => {
          return { id: d.id, value: d.name, type: d.device_type.type };
        })
      );
    });
  }, []);

  const contentPopUp = (
    <section className="modal-filters">
      <img src={closeIcon} onClick={handleClose} />
      <header>
        <h4>Filtra tu búsqueda</h4>
      </header>
      <div className="modal-filters__content">
        <MultiSelectInput
          items={devicesOptions}
          selected={selectDevices}
          label={"Dispositivos"}
          handleChange={handleChangeDevices}
          classInput="modal-filters__select--input"
          classItems="modal-filters__select--item"
          enableSelectAll
        />
        <MultiSelectInput
          items={statusOptions}
          selected={selectStatus}
          label={"Estados"}
          handleChange={handleChangeStatus}
          classInput="modal-filters__select--input"
          classItems="modal-filters__select--item"
          enableSelectAll
        />
        <DateRangePicker setSelectDateRange={setSelectDateRange} filters={filters} />
      </div>
      <footer>
        <button onClick={handleDeleteFilters}>
          <Icon name="trash_can" width={10} height={12} fill="#494F66" />
          Borrar filtros
        </button>
        <button onClick={handleApply}>Aplicar</button>
      </footer>
    </section>
  );

  return (
    <MainAppGenericModal
      open={isOpen}
      content={contentPopUp}
      handleClose={handleClose}
      classContainer="modal-filters-container"
      classModalContainer="modal-filters-modal-container"
    />
  );
};

export default ModalFilters;
