import React from "react";
import renderInput from "helpers/FormGenerator";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";

import "./styles.scss";

export const LocationModal = ({
  fields,
  handleClose,
  handleSelect,
  handleAdd,
}) => {
  const content = (
    <div className="location-modal-content-wrapper">
      <div className="location-modal-content">
        {fields &&
          fields.map((field) => {
            return renderInput(field, handleSelect, {}, "prep-order-field");
          })}
      </div>
      <div className="load-movement-items-buttons-wrapper">
        <div
          className="load-movement-items-button load-movement-items-add"
          onClick={handleAdd}
        >
          {" "}
          AGREGAR{" "}
        </div>
      </div>
    </div>
  );
  return (
    <div className="location-modal-container">
      <AdminLayout
        headerTitle={"Agregar Ubicación"}
        headerOptions={[]}
        content={content}
        closeFunc={handleClose}
        navHidden={true}
      ></AdminLayout>
    </div>
  );
};

export default LocationModal;
