const scopes = [
    {
        name: 'Usuarios',
        id: 'users',
    },
    {
        name: 'Roles',
        id: 'roles',
    },
    {
        name: 'Tags (Plantillas)',
        id: 'config_tag',
    },
    {
        name: 'Productos',
        id: 'items',
    },
    {
        name: 'Stock',
        id: 'stock',
    },

    {
        name: 'Personas',
        id: 'persons',
    },
    {
        name: 'Dispositivos',
        id: 'devices',
    },
    {
        name: 'Ubicaciones',
        id: 'locations'
    },
    {
        name: 'Sucursales',
        id: 'nodes'
    },
    {
        name: 'Aplicaciones externas',
        id: 'apps'
    },
    {
        name: 'Órdenes',
        id: 'orders'
    },    
    {
        name: 'Alertas',
        id: 'alerts'
    },
]


export const specificScopes = [
    {
        name: 'Generar Tags',
        id: 'generate_config_tag',
    },
    {
        name: 'Administrar Tags',
        id: 'admin_tag',
    },
    {
        name: 'Administrar Atributos',
        id: 'admin_attributes',
    },
    {
        name: 'Carga de Items (Archivo)',
        id: 'create_upload_items',
    },
    {
        name: 'Auditoria Stock',
        id: 'auditory_stock',
    },
    {
        name: 'Localizacion Stock',
        id: 'localization_stock',
    },
    {
        name: 'Completar Orden',
        id: 'complete_orders',
    },
    {
        name: 'Rechazar Order',
        id: 'reject_orders',
    },
]

export default scopes;