import "./FilterAlertsMobile.scss";
import ModalFilters from "components/MainApp/organisms/Modals/ModalFilters/ModalFilters";
import filtersIcon from "assets/images/filters-icon.svg";
import { useState } from "react";

const FilterAlertsMobile = ({ filters, setFilters }) => {
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);

  const filtersCount =
    (filters?.devices && filters.devices.length > 0 ? 1 : 0) +
    (filters?.status && filters.status.length > 0 ? 1 : 0) +
    (filters?.dateRange ? 1 : 0)

  const handleOpenFilters = () => {
    setIsOpenPopUp(true);
  };

  return (
    <div className="filter-alerts-mobile">
      <button onClick={handleOpenFilters}>
        <img src={filtersIcon} />
        {filtersCount > 0 && <span>{filtersCount}</span>}
      </button>

      <ModalFilters
        isOpen={isOpenPopUp}
        setIsOpen={setIsOpenPopUp}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
};

export default FilterAlertsMobile;
