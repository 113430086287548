import { useState } from "react";
import i18n from "i18n";

export const useRequest = (
  initialFilters,
  productFilters,
  filterTypeMap,
  isMobile,
  headers
) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);

  function resetAlerts() {
    setErrors({});
    setMessage(null);
  }

  function beforeSubmit() {
    setLoading(true);
    setSuccess(null);
    resetAlerts();
  }

  function afterSubmit() {
    setLoading(false);
  }

  function showSuccess(message) {
    afterSubmit();
    setSuccess(true);
    setMessage(message);
  }

  function showError(message, errors = {}) {
    afterSubmit();
    setSuccess(false);
    setMessage(message);
    setErrors(errors);
  }

  function dealWithError(
    response,
    codeMsgError = "generic.error",
    callback = null
  ) {
    let errors = {};
    if ([400, 401, 409].includes(response.status)) {
      response.json().then((data) => {
        if (Array.isArray(data)) {
          // codeMsgError = data.join(", ");
          errors = data; // Serializer multiples
        } else if ("detail" in data) {
          codeMsgError = data["detail"];
        } else if ("message" in data && "errors" in data) {
          codeMsgError = data["message"];
          errors = data["errors"];
        } else if ("non_field_errors" in data) {
          codeMsgError = data["non_field_errors"];
        } else {
          codeMsgError = i18n.t(codeMsgError);
          errors = data;
        }
        showError(codeMsgError, errors);
        if (callback) callback(codeMsgError, errors);
      });
    } else {
      if ([403].includes(response.status)) {
        codeMsgError = i18n.t(403);
        showError(codeMsgError, errors);
        if (callback) callback(codeMsgError, errors);
      }else{
        codeMsgError = i18n.t(codeMsgError);
        showError(codeMsgError, errors);
        if (callback) callback(codeMsgError, errors);
      }
    }
  }

  return {
    loading,
    setLoading,
    success,
    setSuccess,
    errors,
    setErrors,
    message,
    setMessage,

    beforeSubmit,
    afterSubmit,
    showSuccess,
    showError,
    dealWithError,
  };
};

export default useRequest;
