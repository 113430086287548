import "./DevicesActivationButton.scss";
import { useEffect, useState } from "react";
import { DeviceService } from "services/DeviceService";
import { WSService } from "services/WSServices";
import Loader from "components/MainApp/atoms/Loader";
import ModalDeviceActivation from "../Modals/ModalDevicesActivation/ModalDevicesActivation";
import deviceIcon from "assets/images/device-icon-blue.svg";
import arrowIconSmall from "assets/images/arrow-icon-small.svg";

let globalChannel = null;

const DevicesActivationButton = ({ devicesOptions }) => {
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [options, setOptions] = useState([]);
  const [channel, setChannel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDevices, setActiveDevices] = useState([]);

  const closeRoom = () => {
    if (!globalChannel) return;

    setIsLoading(true);
    setActiveDevices([]);

    DeviceService.stopRead({ channel: channel || globalChannel })
      .then(_ => {
        const ws = WSService.getInstance();
        ws.leaveRoom(channel);
        setChannel(null);
      })
      .catch(_ => {})
      .finally(() => setIsLoading(false));
  };

  const openRoom = deviceSelected => {
    if (deviceSelected.length === 0) return;

    const data = {
      devices: deviceSelected,
      type: "PRESIS",
      ref: "",
      associate: false,
      priority: "RFID"
    };

    setIsLoading(true);

    DeviceService.startRead(data)
      .then(response => {
        setChannel(response.channel);
        const ws = WSService.getInstance();
        var channels = response.channel?.split("-");
        channels?.forEach(ch => {
          ws.joinRoom(ch);
        });
        if (channels?.length > 1) {
          ws.setRoom(response.channel);
        }
      })
      .catch(_ => {
        _.json().then(_data => {
          setDeviceSelected([]);
        });
      })
      .finally(() => {
        setActiveDevices(deviceSelected);
        setIsLoading(false);
      });

    const ws = WSService.getInstance();
    ws.setDevices(deviceSelected);
  };

  useEffect(() => {
    if (!devicesOptions) {
      DeviceService.readersDevices({
        no_page: 1,
        enabled: true,
        includeType: "LOSS_PREVENTION"
      }).then(devices => {
        setOptions(
          devices.map(d => {
            return { id: d.id, value: d.name, type: d.device_type.type };
          })
        );
      });
    }
  }, []);

  useEffect(() => {
    globalChannel = channel;
  }, [channel]);

  // Disconnect WebSocket when dismount component
  useEffect(() => {
    return () => {
      if (globalChannel) {
        closeRoom();
      }
    };
  }, []);

  return (
    <>
      <button
        className="devices-activation-button"
        onClick={() => setIsOpenPopUp(true)}
      >
        <picture>
          <img src={deviceIcon} />
          {activeDevices.length > 0 && <span>{activeDevices.length}</span>}
        </picture>
        <span>Activar dispositivos</span>
        <img src={arrowIconSmall} />
      </button>

      <ModalDeviceActivation
        isOpen={isOpenPopUp}
        setIsOpen={setIsOpenPopUp}
        devicesOptions={devicesOptions || options}
        openRoom={openRoom}
        closeRoom={closeRoom}
        activeDevices={activeDevices}
      />

      {isLoading && <Loader />}
    </>
  );
};

export default DevicesActivationButton;
