import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import useRequest from "hooks/useRequest";
import { Container, Grid } from "@material-ui/core";
import Search from "components/MainApp/atoms/Filters/Search";
import { StockService } from "services/StockService";
import { stockByItemAndLocation } from "helpers/stock/StockHelper";
import { AuthService } from "services/AuthService";
import SimplifedRelocationRow from "./SimplifedRow";
import { LocationService } from "services/LocationService";
import SelectFilter from "components/MainApp/atoms/Filters/SelectFilter";
import filterData from "./filters"
import { OrderService } from "services/OrderService";
import { EventRepository } from "helpers/EventRepository";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { ProductService } from "services/ProductService";

const SimplifedRelocation = props => {
    const history = useHistory();
    const [submitted, setsubmitted] = useState(false);
    const [searchedValue, setSearchedValue] = useState();
    const [groupedItemsLocationStock, setGroupedItemsLocationStock] = useState([])
    const [filters, setFilters] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [selectedTags, setSelectedTags] = useState([]);
    const [enabledButton, setEnabledButton] = useState(false);
    const [resultList, setResulList] = useState([]);
    const [items, setItems] = useState([]);
    const searchRef = useRef();

    const {
        loading,
        beforeSubmit,
        afterSubmit,
        errors,
        dealWithError
    } = useRequest();

    useEffect(() => {
        getZones();
    }, []);

    const getZones = (node) => {
        LocationService.locationsAll({ no_parent: "true", node: AuthService.getCurrentNodeCode() }).then(locations => {
            locations = locations.map(n => {
                n.label = n.name;
                n.value = n.name;
                return n
            })

            filterData[0].options = locations;
            filterData[1].options = [];
            filterData[2].options = [];
            setFilters(filterData);
        })
    };

    const getAreas = (zone, index) => {
        LocationService.locationsAll({ parent: zone }).then(locations => {
            locations = locations.map(n => {
                n.label = n.name;
                n.value = n.name;
                return n
            })
            const newFilters = [...filters];
            newFilters[index + 1].options = locations;
            setFilters(newFilters);
        })
    };

    const filterProducts = (value) => {
      if(value.length > 2) {
        return new Promise((resolve, reject) => {
          var filter = [];
          filter["search"] = value;

          ProductService.productsName({ page_size: 999999, search: value }).then(
            response => {
              resolve(response);
              setResulList(response);
              setItems(response);
            }
          ).catch(error => {
            resolve([]);
            setResulList([]);
            setItems([]);
          });
        });
      }
    }

    const handleSelection = (option, index) => {

        if (index === 0) {
            getAreas(option.id, index)
            const _selectedOptions = [option, {} , {}]
            setSelectedOptions(_selectedOptions)
            setEnabledButton(true);
        }

        if (index === 1) {
            getAreas(option.id, index)
            const _selectedOptions = [selectedOptions[0], option, {}]
            setSelectedOptions(_selectedOptions)
            setEnabledButton(true);
        }

        if (index === 2) {
            const _selectedOptions = [...selectedOptions]
            _selectedOptions[2] = option
            setSelectedOptions(_selectedOptions)
            setEnabledButton(true);
        }
        setSelectedOption(option);
    }

    const handleSearch = (sku) => {
        if (sku == "") return;

        if (items.length > 0) {
          const item = items.find(item => item.label === sku);
          if (item) sku = item?.sku;
        }

        if (sku != "") {
            searchRef.current.instanceRef.cleanInput();
            if (groupedItemsLocationStock.filter(itemLocation => itemLocation.sku == sku).length > 0) {
                EventRepository.notificationSend({
                    label: "el producto ya se encuentra cargado",
                    type: "error",
                });
                return;
            }
            beforeSubmit();
            StockService.stock({
                no_page: 1,
                status: "LOC",
                location__node: AuthService.getCurrentNodeCode(),
                item__sku: sku.trim(),
            })
                .then((res) => {
                    const _groupedStock = stockByItemAndLocation(res);
                    if (_groupedStock.length > 0) {
                        const newValue = {
                            sku: _groupedStock[0].sku,
                            image: _groupedStock[0].image,
                            listGrouped: [..._groupedStock]
                        }
                        var grouped = groupedItemsLocationStock;
                        grouped.push(newValue);

                        setGroupedItemsLocationStock(grouped)
                    }
                    afterSubmit();
                })
                .catch((_) => {
                    afterSubmit();
                });
        }

    }

    const handleApply = () => {

        if (selectedTags.length == 0) {
            EventRepository.notificationSend({
                label: "Debe seleccionar al menos un tag",
                type: "error",
            });
            return;
        }
        let listTags = [];
        selectedTags.forEach(tag => {
            listTags.push(...tag.tags);
        });
        let params = {
            "rfid": listTags,
            "location": selectedOption.id,
            "node": AuthService.getCurrentNodeCode()
        }
        beforeSubmit();
        OrderService.relocationSimplifedMove(params).then((response) => {
            EventRepository.notificationSend({
                label: "Tags reubicados correctamente",
                type: "success",
            });
            setGroupedItemsLocationStock([]);
            filterData[1].options = [];
            filterData[2].options = [];
            setFilters(filterData);
            setEnabledButton(false);
            setSelectedTags([]);
            afterSubmit();
        }).catch(error => {
            console.error(error.message);
            EventRepository.notificationSend({
                label: "Error al reubicar los tags",
                type: "error",
            });
            afterSubmit()
        })

    }

    const goBackFunc = () => {
        history.push("/admin/orders/relocation");
    }

    const updateTagsSelected = (tags, sku) => {
        let listSelectedTags = [...selectedTags];
        let data = {
            tags: tags,
            sku: sku
        }

        listSelectedTags = listSelectedTags.filter(function(item) {
            return item.sku !== sku
        })

        if(tags.length > 0){
            listSelectedTags.push(data);
        }
        setSelectedTags(listSelectedTags);
    }

    const tableProduct = (
        <React.Fragment>{
            groupedItemsLocationStock?.map((grouped, index) => {
                return (
                    <SimplifedRelocationRow key={index} groupedItem={grouped} updateTagsSelected={updateTagsSelected} />
                );

            })}
        </React.Fragment>
    )

    const content = (
        <div className="outter-wrapper">
            <Container className="container-simplifed-relocation">
                <div className={`simplifed-relocation-filter-panel-container`}>
                    <div className="simplifed-relocation-filter-panel-row">
                        <Search
                            placeholder="Ingrese el código SKU"
                            resultList={resultList}
                            value={searchedValue}
                            handleSubmit={(sku) => {
                                handleSearch(sku.trim());
                            }}
                            filterProducts={filterProducts}
                            handleSubmitKey={() => searchRef.current.instanceRef.cleanInput()}
                            ref={searchRef}
                        />
                    </div>
                </div>

                <div className="auditoria-filter-panel-reubication-wrapper">

                    <div className={`auditoria-filter-panel-container-out`}>
                    {tableProduct}
                        <div className={`auditoria-filter-panel-container simplified-reubication`}>
                            <div className={`auditoria-filter-panel-inputs`}>
                                {filters?.map((filter, i) => {
                                    if (filter.options && filter.options?.length > 0) {
                                        return (
                                            <SelectFilter
                                                key={filter.label}
                                                label={filter.label}
                                                options={filter.options}
                                                selectedOption={
                                                    selectedOptions && selectedOptions[i]
                                                }
                                                handleSelection={(e) => handleSelection(e, i)}
                                                placeholder={filter.placeholder}
                                            ></SelectFilter>
                                        );
                                    }

                                })}
                            </div>
                            <div className={`auditoria-filter-panel-buttons`}>
                                <button
                                    className={`auditoria-filter-panel-filter-apply ${enabledButton ? "" : "disabled"
                                        }`}
                                    disabled={!enabledButton}
                                    onClick={() => handleApply(selectedOptions)}
                                >
                                    REUBICAR
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )


    return (
        <div className="load-relocation-order-container">
            <AdminLayout
                headerTitle={"Reubicación Simplificada"}
                headerOptions={[]}
                content={content}
                goBackFunc={goBackFunc}
                navHidden={true}
            ></AdminLayout>

            {(loading || submitted) && <Loader />}
        </div>
    );
}

export default SimplifedRelocation;